import React, { useState, useEffect } from 'react';
import M from 'materialize-css';
import Loader from '../../components/loader';
import { getUserInfo, servelURL, date } from '../../config';
import Button from '../../components/button';
import backgroundImage from '../../config/logo.jpg'
import BlinkingWord from '../../components/round';
import DocumentViewer from '../../components/documentViewer'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer';

const BlogList = function (props) {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('')
    const [showScrollButton, setShowScrollButton] = useState(false);


    useEffect(() => {
        document.title = 'Testimonials | Bakongwa Doctors';
        M.AutoInit();
        mount();
        const handleScroll = () => {
            const shouldShowButton = window.scrollY > 200;
            setShowScrollButton(shouldShowButton);
        };

        window.addEventListener('scroll', handleScroll);

        return function cleanup() {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        mount();
        // eslint-disable-next-line
    }, [searchQuery]);

    async function mount() {
        try {
            setLoading(true);
            const condition = JSON.stringify({ title: { $regex: searchQuery, $options: 'i' } });
            const select = JSON.stringify({});
            const sort = JSON.stringify({ createdAt: -1 });
            const schema = 'blog';
            const joinForeignKeys = true;
            const parameters = `schema=${schema}&condition=${condition}&sort=${sort}&select=${select}&joinForeignKeys=${joinForeignKeys}`;

            let response = await fetch(`${servelURL}/list-all?${parameters}`, {
                method: 'GET',
                mode: 'cors',
            });

            response = await response.json();
            setLoading(false);
            if (response.success) {
                setList(response.message);
            } else {
                new M.Toast({ html: response.message });
            }
        } catch (error) {
            setDisabled(true);
            setLoading(false);
            setList([]);
            if (error instanceof Error) {
                new M.Toast({ html: error.message });
            } else {
                console.log(error);
            }
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Paginate the list
    const itemsPerPage = 6;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = list.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <main>
            <Navbar />
            {loading ? <Loader /> : null}
            <div
                className='background-image'
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    opacity: 0.2,
                    zIndex: -1,
                }}
            >
            </div>
            <div className="row" style={{ marginTop: '0.5rem' }}>
                <div className="col s12 m10 offset-m1">
                    <div className="input-field">
                        <i className="material-icons prefix">search</i>
                        <input
                            type="text"
                            id="search"
                            placeholder="Search by title"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <label htmlFor="search" className="active">Search</label>
                    </div>
                </div>
            </div>
            <div className="row">
                {currentItems.map((blog, index) => {
                    return (
                        <div key={index} className="col s12 m4">
                            <div className="card hoverable card-fixed-height">
                                <div
                                    className="card-image"
                                    style={{
                                        height: '200px', // Adjust this value to your desired height
                                    }}
                                >
                                    <div
                                        className="image-wrapper"
                                        style={{
                                            position: 'relative',
                                            height: '100%',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <img
                                            src={`${blog.pic}`}
                                            className="materialboxed card-image-img"
                                            alt="pic"
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                            }}
                                        />
                                    </div>
                                    {getUserInfo()?.role === 'admin' && (
                                        <button
                                            className="btn-floating halfway-fab waves-effect waves-light green"
                                            onClick={() => {
                                                props.history.push({
                                                    state: { blog: blog },
                                                    pathname: '/blog/view',
                                                });
                                            }}
                                            disabled={disabled ? true : false}
                                        >
                                            <i className="material-icons">info</i>
                                        </button>
                                    )}
                                </div>
                                <div className="card-action">
                                    <span className="card-title black-text">{blog?.title?.replace(/-/g, ' ')?.toUpperCase()}</span>
                                    <pre className="green-text">{date(blog.createdAt)}</pre>
                                    <BlinkingWord text={blog?.title?.name?.toUpperCase()} />
                                </div>
                                <div className="card-content">
                                    <div>
                                        <DocumentViewer document={blog?.description?.substring(0, 150) + '...'} />
                                    </div>
                                </div>
                                <div className="card-action">
                                    <Button
                                        onClick={() => {
                                            props.history.push({
                                                state: { blog: blog },
                                                pathname: `blog/${blog.title}`,
                                            });
                                        }}
                                        disabled={disabled ? true : false}
                                        type="Read More"
                                        className="btn-small green"
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="row" style={{ marginTop: '2rem' }}>
                <div className="col s12 m10 offset-m1">
                    <ul className="pagination">
                        {Array.from({ length: Math.ceil(list.length / itemsPerPage) }).map((_, index) => {
                            const pageNumber = index + 1;
                            return (
                                <li
                                    key={index}
                                    className={pageNumber === currentPage ? 'active' : 'waves-effect'}
                                    onClick={() => handlePageChange(pageNumber)}
                                >
                                    <a href="#!">{pageNumber}</a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            {showScrollButton && (
                <button className='btn-floating btn-large green scroll-button' onClick={scrollToTop}>
                    Top
                </button>
            )}
            <Footer />
        </main>
    );
};

export default BlogList
