import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Navbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showAnnouncement: true
        }
    }

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
        let doc = document.documentElement
        let w = window

        let prevScroll = w.scrollY || doc.scrollTop
        let curScroll;
        let direction = 0
        let prevDirection = 0

        let navbar = document.getElementById('navbar')

        function checkScroll() {
            curScroll = w.scrollY || doc.scrollTop

            if (curScroll > prevScroll) {
                //scrolled up
                direction = 2;
            }
            else if (curScroll < prevScroll) {
                //scrolled down
                direction = 1;
            }

            if (direction !== prevDirection) {
                toggleHeader(direction, curScroll);
            }

            prevScroll = curScroll;
        }

        function toggleHeader(direction, curScroll) {
            if (direction === 2 && curScroll > 50) {
                //replace 52 with the height of your header in px
                navbar?.classList.add('hide');
                prevDirection = direction;
            }
            else if (direction === 1) {
                navbar?.classList.remove('hide');
                prevDirection = direction;
            }
        }

        window.addEventListener('scroll', checkScroll);
    }

    handleAnnouncementClose = () => {
        this.setState({ showAnnouncement: false })
    }

    render() {
        return (
            <React.Fragment>
                {this.state.showAnnouncement && (
                    <div className="announcement-bar">
                        <p>Soon, we will have a mobile application for this website.</p>
                        <button onClick={this.handleAnnouncementClose} className="close-button">X</button>
                    </div>
                )}
                <div className="navbar-fixed">
                    <nav id="navbar">
                        <div className="nav-wrapper green darken-2">
                            <h6 className="brand-logo center" style={{ fontSize: "21px" }}>Bakongwa Doctors</h6>
                            <Link to="#" data-target="mobile-demo" className="sidenav-trigger show-on-large"><i className="material-icons">menu</i></Link>
                            <ul className="hide">
                                <li><a href="/">Home</a></li>
                                <li><a href="/Spells">Spells</a></li>
                                <li><Link to="/Magicrings">Magic Rings</Link></li>
                                <li><Link to="/TraditionalHerbs">Traditional Herbs</Link></li>
                                <li><Link to="/counselling">Traditional Counselling</Link></li>
                                <li><Link to="/Appointment">Make an Appointment</Link></li>
                                <li><Link to="/Aboutus">About Us</Link></li>
                                <li><Link to="/Contactus">Contact Us</Link></li>
                                <li><Link to="/blog/list">Bakongwa Testimonials</Link></li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <ul className="sidenav green lighten-5" id="mobile-demo">
                    <li><a href="/">Home</a></li>
                    <li><a href="/Spells">Spells</a></li>
                    <li><a href="/Magicrings">Magic Rings</a></li>
                    <li><a href="/TraditionalHerbs">Traditional Herbs</a></li>
                    <li><a href="/counselling">Traditional Counselling</a></li>
                    <li><a href="/Appointment">Make an Appointment</a></li>
                    <li><a href="/Aboutus">About Us</a></li>
                    <li><a href="/Contactus">Contact Us</a></li>
                    <li><a href="/blog/list">Bakongwa Testimonials</a></li>
                </ul>
            </React.Fragment>
        )
    }
}
