export const serverUrl = 'https://muringo-backend.vercel.app/api'
export const servelURL = 'https://muringo-backend.vercel.app/api'
// export const servelURL = 'http://127.0.0.1:5000/api'

export function getUserInfo(info) {
    try {
        let user = atob(localStorage.getItem('data'))
        // user = atob(user)
        if (user) {
            if (info) {
                const parsedUser = JSON.parse(user)
                return parsedUser[info]
            }
            else
                return JSON.parse(user)
        }
        else
            return false

    } catch (error) {
        if (error instanceof Error)
            console.log(`${error.message}`)
        else
            console.error(error)
    }
}

export function date(date) {
    const day = new Date(date).getDate();
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    const month = months[new Date(date).getMonth()];
    const year = new Date(date).getFullYear();

    return `${day} ${month} ${year}`;
}

export const formatNumber = (num) => {
    const str = String(num);
    let result = '';
    let count = 0;

    for (let i = str.length - 1; i >= 0; i--) {
        result = str[i] + result;
        count++;

        if (count === 3 && i !== 0) {
            result = ',' + result;
            count = 0;
        }
    }

    return result;
};